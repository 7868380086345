import React, { useState, useEffect } from "react";
import { Box, HStack, VStack, Text, Heading, Flex, Image, Input, Button, Skeleton } from "@chakra-ui/react";
import CorrectAnswerIcon from "../Images/CorrectAnswerIcon.svg"; // Adjust the path as needed
import WrongAnswerIcon from "../Images/WrongAnswerIcon.svg"; // Adjust the path as needed
import PartialAnswerIcon from "../Images/PartiallyCorrectAnswerIcon.svg"; // Adjust the path as needed
import axios from "axios";
import { BASE_API_URL } from './config';


const FeedBack = ({ loading, questionAttemptGuid, feedbackData, totalMarks, userType }) => {
  const [newMark, setNewMark] = useState("");
  const [error, setError] = useState(null);
  const [updatedFeedback, setUpdatedFeedback] = useState(feedbackData);


  const { sampleImage, feedback, feedbackStatus, mark } = updatedFeedback;

  const [bgColor, setBgColor] = useState("#EDFFEE");
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    setUpdatedFeedback(feedbackData);
  }, [feedbackData]);

  const handleSubmit = async () => {
    try {
      const requestBody = {
        feedback_mark: parseInt(newMark, 10),
        completion_status: "COMPLETE",
      };

      const response = await axios.put(
        `${BASE_API_URL}/api/v1/user-question-attempts/${questionAttemptGuid}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUpdatedFeedback((prevFeedback) => ({
        ...prevFeedback,
        feedbackStatus: response.data.feedback_correctness,
        mark: response.data.feedback_mark,
      }));

      setError(null);
    } catch (err) {
      setError("There was an error submitting your mark.");
      console.error(err);
    }
  };

  useEffect(() => {
    if (feedbackStatus === "INCORRECT") {
      setBgColor("#FFE9E9");
    } else if (feedbackStatus === "PARTIALLY CORRECT") {
      setBgColor("#FFF6E4");
    } else {
      setBgColor("#EDFFEE");
    }
  }, [feedbackStatus]);

  const handleMarkChange = (e) => {
    setNewMark(e.target.value);
  };

  // If loading, show the skeleton instead of the actual content
  if (loading) {
    return true;
  }

  return (
    <Box width="548px" padding="24px" bg={bgColor} borderRadius="14px" marginTop="24px" position="relative">
      <HStack justify="space-between">
        <Flex display="inline-flex" gap="4px" marginBottom="14px">
          <Image
            src={
              feedbackStatus === "CORRECT"
                ? CorrectAnswerIcon
                : feedbackStatus === "PARTIALLY CORRECT"
                ? PartialAnswerIcon
                : WrongAnswerIcon
            }
            alt={`${feedbackStatus}-icon`}
          />
          <Heading color="#021A04" fontSize="14px" fontWeight="400">
            {feedbackStatus}
          </Heading>
        </Flex>
        <Flex>
          { feedbackStatus == "PARTIALLY CORRECT" ? " ":
          <Heading color="#021A04" fontSize="12px" fontWeight="300">
            {`${mark} marks / ${totalMarks}`}
          </Heading>  } 
        </Flex>
      </HStack>

      <VStack spacing={1} alignItems="left">
        <Heading color="#021A04" fontSize="12px" fontWeight="400">
          Feedback
        </Heading>

        <Box position="relative" width="100%">
          {userType === "DENIED" ? (
            <>
              {/* Blurred feedback text */}
              <Box
                filter="blur(5px)"
                pointerEvents="none"
                userSelect="none"
                position="relative"
                height="70px" // Set height to make sure blur is visible
                zIndex="1"
              >
                <Text color="#021A04" fontSize="12px" fontWeight="300">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Text>
              </Box>
              {sampleImage && sampleImage !== "no image url" && (
                <Box filter="blur(5px)" mt={4} zIndex="1">
                  <Image src={sampleImage} alt="sample-image" />
                </Box>
              )}
              {/* Single upgrade message overlay for both feedback and image */}
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="14px"
                zIndex="2"
              >
                <Text fontSize="14px" color="#021A04" fontWeight="350px">
                  Upgrade to premium to access detailed feedback and sample image
                </Text>
              </Box>
            </>
          ) : (
            <>
              {/* Normal Feedback if user has permission */}
              <Text color="#021A04" fontSize="12px" fontWeight="250">
                {feedback}
              </Text>
              {sampleImage && sampleImage !== "no image url" && (
                <>
                  <Heading color="#021A04" fontSize="12px" fontWeight="400">
                    This type of question might not be answered correctly by AI. Please mark yourself based on the sample answer image below:
                  </Heading>
                  <Image src={sampleImage} alt="sample-image" />
                  <Input
                    type="number"
                    placeholder="Please enter your new mark here"
                    value={newMark}
                    onChange={handleMarkChange}
                    borderRadius="14px"
                    border="0.5px solid var(--Main-font-color, #021A04)"
                    mt="12px"
                  />
                  <Button
                    onClick={handleSubmit}
                    color="#F1F1F1"
                    fontSize="14px"
                    fontWeight="400"
                    mt="14px"
                    bg="#070707"
                    borderRadius="14px"
                    _hover={{
                      background: "var(--Primary, #F1F1F1)",
                      color: "var(--Main-font-color, #021A04)",
                      boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.14)",
                    }}
                  >
                    Submit Mark
                  </Button>
                  {error && <Text color="red.500">{error}</Text>}
                </>
              )}
            </>
          )}
        </Box>
      </VStack>
    </Box>
  );
};

export default FeedBack;
