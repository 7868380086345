import React from "react";
import { Box, Flex } from "@chakra-ui/react";

const DotsLoading = () => {
  return (
    <Flex alignItems="center" mt={5}>
      <Box
        as="span"
        height="8px"
        width="8px"
        borderRadius="50%"
        background="#021A04"
        mx="2px"
        animation="dots-blink 1.4s infinite both"
      />
      <Box
        as="span"
        height="8px"
        width="8px"
        borderRadius="50%"
        background="#021A04"
        mx="2px"
        animation="dots-blink 1.4s infinite both"
        animationDelay="0.2s"
      />
      <Box
        as="span"
        height="8px"
        width="8px"
        borderRadius="50%"
        background="#021A04"
        mx="2px"
        animation="dots-blink 1.4s infinite both"
        animationDelay="0.4s"
      />
      <style>
        {`
          @keyframes dots-blink {
            0% {
              opacity: 0.2;
            }
            20% {
              opacity: 1;
            }
            100% {
              opacity: 0.2;
            }
          }
        `}
      </style>
    </Flex>
  );
};

export default DotsLoading;
