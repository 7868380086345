import React, { useState, useRef, useEffect } from "react";
//import { Tooltip } from "@chakra-ui/react";
import {
  Box,
  Flex,
  Textarea,
  Button,
  Image,
  Input,
  IconButton,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import UploadImageIcon from "../Images/Upload-Image-Icon.svg";
import SubmitIcon from "../Images/Submit-Icon.svg";
import UploadImageIconDark from "../Images/Upload-Image-Icon-Dark.svg";
import SubmitIconDark from "../Images/Submit-Icon-Dark.svg";
import html2canvas from "html2canvas"; 
import { Stage, Layer, Line } from "react-konva";
import DotsLoading from "./DotsLoading"; 

const superscriptMap = {
  0: "⁰",
  1: "¹",
  2: "²",
  3: "³",
  4: "⁴",
  5: "⁵",
  6: "⁶",
  7: "⁷",
  8: "⁸",
  9: "⁹",
};

const subscriptMap = {
  0: "₀",
  1: "₁",
  2: "₂",
  3: "₃",
  4: "₄",
  5: "₅",
  6: "₆",
  7: "₇",
  8: "₈",
  9: "₉",
};

const transformText = (text) => {
  return text
    .replace(/([a-zA-Z])\^(\d+)/g, (match, base, exp) => {
      const superscript = exp
        .split("")
        .map((char) => superscriptMap[char] || char)
        .join("");
      return `${base}${superscript}`;
    })
    .replace(/([a-zA-Z])_(\d+)/g, (match, base, sub) => {
      const subscript = sub
        .split("")
        .map((char) => subscriptMap[char] || char)
        .join("");
      return `${base}${subscript}`;
    });
};



const QuestionAnswerBox = ({
  questionNum,
  handleSubmitAnswer,
  answerFormat: initialAnswerFormat,
  existingAnswerText = "", // Pre-fill text from user_question_attempt
  existingImages = [],      // Pre-fill images from user_question_attempt_attachments
  snapshot = null,          // Snapshot from annotation (optional)
  selectedPartGuid,         // Use selectedPartGuid directly
  tool,
  strokeWidth,
  timeUp,
  testStarted,
  Loading

  }) => {
  const [answer, setAnswer] = useState(existingAnswerText); // Pre-fill answer text
  const [uploadedImages, setUploadedImages] = useState(
    existingImages.map((image) => ({ name: image, url: encodeURI(image) })) // Pre-fill images
  );
  const [snapshotAdded, setSnapshotAdded] = useState(false); // Flag to track if snapshot has been added
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadIcon, setUploadIcon] = useState(UploadImageIcon);
  const [submitIcon, setSubmitIcon] = useState(SubmitIcon);
  const [answerFormat, setAnswerFormat] = useState(initialAnswerFormat); // Editable answer format
  const [localSnapshot, setLocalSnapshot] = useState(snapshot); // Maintain local snapshot state
  const fileInputRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [lines, setLines] = useState([]);
  const isDrawing = useRef(false);
  const stageRef = useRef(null);
  const containerRef = useRef(null);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const tooltipTimeout = useRef(null);


  useEffect(() => {
    if (timeUp) {
      // Perform any necessary cleanup or additional actions on time-up
      console.log("Time is up! Hiding submit button.");
    }
  }, [timeUp]);


  useEffect(() => {
    // Update local snapshot only if the incoming snapshot is different
    if (snapshot && snapshot !== localSnapshot && selectedPartGuid) {
      setLocalSnapshot(snapshot);
      setSnapshotAdded(false); // Reset flag when new snapshot is provided
    }
  }, [snapshot, selectedPartGuid, localSnapshot]);

  useEffect(() => {
    if (localSnapshot && !snapshotAdded) {
      const snapshotName = `Snapshot-${selectedPartGuid}`;
      const snapshotExists = uploadedImages.some((image) => image.name === snapshotName);

      // Only add snapshot if it doesn't already exist and it hasn't been added previously in this lifecycle
      if (!snapshotExists) {
        setUploadedImages((prevImages) => [
          ...prevImages,
          { name: snapshotName, url: localSnapshot },
        ]);
        setSnapshotAdded(true); // Set flag to true after adding
      }
    }
  }, [localSnapshot, selectedPartGuid, snapshotAdded]); // Trigger this effect only when `localSnapshot` or `selectedPartGuid` changes

  const handlePaste = async (event) => {
    const clipboardItems = event.clipboardData.items;
    for (const item of clipboardItems) {
      if (item.type.startsWith("image")) {
        const file = item.getAsFile();
        const reader = new FileReader();
        reader.onload = (e) => {
          setUploadedImages((prevImages) => [
            ...prevImages,
            { name: `PastedImage-${Date.now()}`, url: e.target.result },
          ]);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current && stageRef.current) {
        stageRef.current.width(containerRef.current.clientWidth);
      }
    };
  
    // Set initial width
    handleResize();
  
    // Listen for resize events
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
   // Helper function to detect if click is near a line's points
   const isNearPoint = (point, x, y) => {
    const threshold = 10; // Click proximity threshold
    return Math.abs(point[0] - x) < threshold && Math.abs(point[1] - y) < threshold;
  };

  const handleMouseDown = (e) => {
    const stage = e.target.getStage();
    const { x, y } = stage.getPointerPosition();

    if (tool === "eraser") {
      // Check if any line contains a point near the click position
      const updatedLines = lines.filter((line) =>
        !line.points.some((point, index) => index % 2 === 0 && isNearPoint([line.points[index], line.points[index + 1]], x, y))
      );
      setLines(updatedLines);
    } else if (tool === "pen") {
      isDrawing.current = true;
      const newLine = {
        points: [],
        strokeWidth,
        globalCompositeOperation: "source-over",
        tool: "pen",
      };
      setLines([...lines, newLine]);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDrawing.current) return;
    const stage = stageRef.current.getStage();
    const point = stage.getPointerPosition();
    setLines((prevLines) => {
      const lastLine = prevLines[prevLines.length - 1];
      if (lastLine) {
        lastLine.points = lastLine.points.concat([point.x, point.y]);
        return [...prevLines.slice(0, -1), lastLine];
      }
      return prevLines;
    });
  };


  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const captureAnnotation = async () => {
    const dataURL = stageRef.current.toDataURL();
    return dataURL;
};
  // Handle image uploads through file input
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      const fileObj = { name: file.name, url: "" };

      reader.onloadend = () => {
        fileObj.url = reader.result;
        setUploadedImages((prevImages) => [...prevImages, fileObj]);
      };

      reader.readAsDataURL(file);
    });
  };

  
  // Remove image from uploadedImages state
  const handleRemoveImage = (imageName) => {
    setUploadedImages((prevImages) => prevImages.filter((image) => image.name !== imageName));
  };

  // Remove snapshot from uploadedImages state
  const handleRemoveSnapshot = () => {
    setUploadedImages((prevImages) => prevImages.filter((image) => !image.name.startsWith("Snapshot")));
    setSnapshotAdded(false); // Allow adding snapshot again if removed
  };

  // Handle answer submission
  const handleSubmit = async () => {
  
  let updatedImages = [...uploadedImages];
  
  // Only capture and add annotation image if lines are present
  if (lines.length > 0) {
    const annotationImage = await captureAnnotation();
    updatedImages = [...uploadedImages, { name: "annotation", url: annotationImage }];
  }

  handleSubmitAnswer(questionNum, answer, updatedImages);
  
};

  // Open image in a modal for preview
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    onOpen();
  };

  const handleAnswerChange = (e) => {
    const inputValue = e.target.value;
    const transformedValue = transformText(inputValue);
    setAnswer(transformedValue);
  };

  // Handle answer format click
  const handleAnswerFormatClick = () => {
    setAnswer(`${answer} ${answerFormat}`);
    setAnswerFormat(""); // Clear the format as it's now part of the text
  };
  
  
  // Show tooltip on mouse enter and hide after a delay
  const handleMouseEnter = () => {
    setTooltipVisible(true);
    tooltipTimeout.current = setTimeout(() => {
      setTooltipVisible(false);
    }, 3000);
  };

  // Hide tooltip immediately on mouse leave
  const handleMouseLeave = () => {
    clearTimeout(tooltipTimeout.current);
    setTooltipVisible(false);
  };

  

  return (
    <Flex flexDirection="column" mt={4} alignItems="right" spacing={4} width="100%" zIndex={10}>
      <Box
        ref={containerRef}
        width="100%"
        height="auto"
        borderRadius="14px"
        border="2px solid #D7D7D7"
        position="relative"
        background="#FCFCFC"
        overflow="hidden"
        padding="12px"
      >
        <Textarea
          value={answer}
          onChange={handleAnswerChange}
          onPaste={handlePaste}
          placeholder="Write your answers here, upload an image answer or annotate below"
          fontFamily="Arial"
          color="black"
          fontSize="14px"
          fontStyle="normal"
          lineHeight="normal"
          border="none"
          resize="none"
          _focus={{ outline: "none" }}
          width="100%"
          height="100%"
          maxHeight="200px"
          overflowY="auto"
          position="relative" // Ensure it serves as a parent for the answerFormat positioning
          onClick={() => {
            if (answerFormat && !answer.includes(answerFormat)) {
              setAnswer((prev) => `${prev} ${answerFormat}`); // Append answer format to the answer
              setAnswerFormat(""); // Clear the answer format after adding
            }
          }}
      
        />

        {/* Display answer format text */}
        {answerFormat && !answer.includes(answerFormat) && (
          <Text
            fontSize="14px"
            color="gray"
            position="absolute"
            bottom="171px"
            right="28px"
            cursor="pointer"
            onClick={() => {
              setAnswer((prev) => `${prev}${answerFormat}`); // Add to the answer
              setAnswerFormat(""); // Clear the answer format text
            }}
          >
            {answerFormat}
          </Text>
        )}

         <Box
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ marginTop: "10px" }}
        >

        <Stage
          ref={stageRef}
          width={containerRef.current ? containerRef.current.clientWidth : 300} // Adaptive width
          height={150}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseUp={handleMouseUp}
          style={{ border: "1px solid #d7d7d7", marginTop: "10px" }}
        >
          <Layer>
            {lines.map((line, i) => (
              <Line key={i} points={line.points} stroke="red" strokeWidth={line.strokeWidth} lineCap="round"  lineJoin="round"
              globalCompositeOperation={line.globalCompositeOperation}
              />
            ))}
          </Layer>
        </Stage>
        
        
         {/* Tooltip shown near the annotation area */}
         {tooltipVisible && (
            <Box
              position="absolute"
              top="-30px"
              left="50%"
              transform="translateX(-50%)"
              bg="gray.700"
              color="white"
              px={2}
              py={1}
              borderRadius="4px"
              fontSize="12px"
              zIndex="1000"
              pointerEvents="none"
            >
              Annotate only here
            </Box>
          )}
        </Box>

        


        <Input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          display="none"
          onChange={handleImageUpload}
          multiple
        />

        {/* Display uploaded images */}
        {uploadedImages.length > 0 && (
          <Flex wrap="wrap" gap={2} mt={2} justifyContent="flex-start">
            {uploadedImages.map((image) => (
              <Box key={image.name} position="relative" width="auto">
                <Image
                  src={image.url}
                  alt={image.name}
                  boxSize="75px"
                  objectFit="cover"
                  borderRadius="8px"
                  onClick={() => handleImageClick(image.url)}
                  cursor="pointer"
                />
                <IconButton
                  icon={<CloseIcon />}
                  size="xs"
                  color="white"
                  background="black"
                  borderRadius="50%"
                  position="absolute"
                  top="5px"
                  right="5px"
                  onClick={() => handleRemoveImage(image.name)}
                  _hover={{ background: "#4BFF5C" }}
                />
              </Box>
            ))}
          </Flex>
        )}
      </Box>

      {/* Modal for viewing full image */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {selectedImage && (
              <Image src={selectedImage} alt="Selected" width="100%" objectFit="contain" />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <HStack mt={4} spacing={4} alignItems="right">
        <Button
          padding="8px 18px"
          alignItems="center"
          gap="4px"
          borderRadius="14px"
          background="var(--Main-font-color, #021A04)"
          color="var(--Primary, #F1F1F1)"
          fontSize="14px"
          fontWeight="400"
          onClick={() => fileInputRef.current.click()}
          onMouseEnter={() => setUploadIcon(UploadImageIconDark)}
          onMouseLeave={() => setUploadIcon(UploadImageIcon)}
          _hover={{
            background: "var(--Primary, #F1F1F1)",
            color: "var(--Main-font-color, #021A04)",
            boxShadow: "0px 0px 5.9px 0px rgba(0, 0, 0, 0.14)",
          }}
        >
          <Image src={uploadIcon} alt="Upload Answer" />
          Upload Answer Photo
        </Button>

        {(!testStarted && !timeUp) && (
        <Button
          padding="8px 18px"
          alignItems="center"
          gap="4px"
          borderRadius="14px"
          background="var(--Main-font-color, #021A04)"
          color="var(--Primary, #F1F1F1)"
          fontSize="14px"
          fontWeight="400"
          onClick={!Loading ? handleSubmit : null} // Prevent clicking when loading
          onMouseEnter={() => !Loading && setSubmitIcon(SubmitIconDark)} // Only change icon on hover if not loading
          onMouseLeave={() => !Loading && setSubmitIcon(SubmitIcon)} // Only reset icon if not loading
          _hover={{
            background: !Loading ? "var(--Primary, #F1F1F1)" : "var(--Main-font-color, #021A04)", // No hover effect when loading
            color: !Loading ? "var(--Main-font-color, #021A04)" : "var(--Primary, #F1F1F1)",
            boxShadow: !Loading ? "0px 0px 5.9px 0px rgba(0, 0, 0, 0.14)" : "none",
          }}
          >
          {!Loading && <Image src={submitIcon} alt="Submit Answer" />} {/* Hide icon when loading */}
          {Loading ? "Submitting ..." : "Submit Answer"}
        </Button>

        )}
      </HStack>
      {Loading && <DotsLoading />}

      
    </Flex>
  );
};

export default QuestionAnswerBox;
