import React, { useState, useEffect } from "react";
import { Box, RadioGroup, Stack, Radio, Button, Image } from "@chakra-ui/react";
import SubmitIcon from "../Images/Submit-Icon.svg";
import SubmitIconDark from "../Images/Submit-Icon-Dark.svg";
import Dotsloading from "./DotsLoading";

const MultipleChoiceDisplay = ({
  questionGuid,
  options = [],
  existingValue = "",
  onSubmitAnswer,
  loading = false,
}) => {
  const [selectedValue, setSelectedValue] = useState(existingValue);

  // Preloaded answers)
  useEffect(() => {
    setSelectedValue(existingValue);
  }, [existingValue]);

  const handleSubmit = async () => {
    if (!selectedValue) {
      alert("Please select an option before submitting!");
      return;
    }

    await onSubmitAnswer(questionGuid, selectedValue, []);
  };

  return (
    <Box mt={4}>
      <RadioGroup value={selectedValue} onChange={setSelectedValue}>
        <Stack direction="column">
          {options.map((option) => (
            <Radio
              key={option.label}
              value={option.label}
              sx={{
                "&.chakra-radio__control": {
                  borderColor: "gray",
                  backgroundColor: "white",
                },
                "&.chakra-radio__control[data-checked]": {
                  backgroundColor: "#4BFF5C",
                  borderColor: "#4BFF5C",
                },
                "&.chakra-radio__label": {
                  color: "#021A04",
                  fontWeight: "400",
                  fontSize: "14px",
                },
              }}
            >
              {`${option.label} ${option.text}`}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>

      <Button
        padding="8px 18px"
        alignItems="center"
        gap="4px"
        borderRadius="14px"
        background="var(--Main-font-color, #021A04)"
        color="var(--Primary, #F1F1F1)"
        fontSize="14px"
        fontWeight="400"
        mt={9}
        onClick={!loading ? handleSubmit : null} // Prevent clicking when loading
          onMouseEnter={() => !loading && {SubmitIconDark} } // Only change icon on hover if not loading
          onMouseLeave={() => !loading && {SubmitIcon} } // Only reset icon if not loading
          _hover={{
            background: !loading ? "var(--Primary, #F1F1F1)" : "var(--Main-font-color, #021A04)", // No hover effect when loading
            color: !loading ? "var(--Main-font-color, #021A04)" : "var(--Primary, #F1F1F1)",
            boxShadow: !loading ? "0px 0px 5.9px 0px rgba(0, 0, 0, 0.14)" : "none",
          }}
          >
          {!loading && <Image src={SubmitIcon} alt="Submit Answer" />} {/* Hide icon when loading */}
          {loading ? "Submitting ..." : "Submit Answer"}
        </Button>
      {loading && <Dotsloading/>}
    </Box>
  );
};

export default MultipleChoiceDisplay;


